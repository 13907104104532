<template>
  <section class="outContainer">
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
      <span>订单管理</span>
    </div>
    <!-- 搜索 -->
    <div class="search">
        <el-input class="each" placeholder="请输入商品名称" v-model="searchInfo.param.productName"></el-input>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close"  @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="handleAdd(null)">新增</el-button>
    </div>
    <!-- 表格数据 -->
    <div class="tableContainer">
      <el-table :data="tableList" style="width: 100%" class="table" height="100%" fit >
        <el-table-column align='center' label="商户名称" prop="merchantName"></el-table-column>
        <el-table-column align='center' label="商品名称" prop="productName"></el-table-column>
        <el-table-column align='center' label="数量" prop="number"></el-table-column>
        <el-table-column align='center' label="费用(元)" prop="money"></el-table-column>
        <el-table-column align='center' label="备注" prop="remark"></el-table-column>
        <el-table-column align='center' label="创建时间" prop="createTimeStr"></el-table-column>
        <el-table-column align='center' label="操作" fixed="right" width="220">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleAdd(scope.row)">编辑</el-button>
              <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页-->
    <PaginationVue v-bind:child-msg="searchInfo" @callFather="callFather"></PaginationVue>
    <!-- 新增、编辑 -->
    <el-drawer
      :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      size="50%">
      <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row" :merchantId="merchantId"></add>
    </el-drawer>
  </section>
</template>

<script>
import PaginationVue from '@/components/Pagination.vue';
import add from './add.vue';
import { getMerchantOrderList,delMerchantOrder } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
        tableList:[], // 表格数据
        searchInfo:{ // 搜索条件
            pageNo:1,
            pageSize:10,
            total:0,
            param:{
              productName:"",
              name:"",
              merchantId:'',
            }
        }, 
        addDrawer:false, // 控制侧边新增弹框是否显示
        row:null, // 当前操作数据
        merchantId:null, // 商户id
    }
  },

  components: {
    PaginationVue,
    add,
  },

  computed: {},

  mounted() {
    this.searchInfo.param.merchantId = this.$route.query.merchantId;
    this.merchantId = this.$route.query.merchantId;
    this.$nextTick(()=>{
      this.init();
    })
  },

  methods: {
    // 返回
    back(){
      this.$router.go(-1)
    },
    // 搜索事件
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo = {
          pageNo:1,
          pageSize:10,
          param:{
            productName:"",
          }
        }
      }else{
        this.searchInfo.pageNo = 1;
      }
      this.init();
    },
    // 获取表格数据
    async init(){
      await getMerchantOrderList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element => {
            element.createTimeStr = timeChange(element.createTime,'seconds');
          });
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
          this.$message({
            message:res.msg,
            type:'fail',
          })
        }
      })
    },
    // 打开新增、编辑
    handleAdd(row){
      this.row = row;
      this.addDrawer = true
    },
    // 关闭侧边弹框
    handleClose(){
      this.addDrawer = false;
      this.init();
    },
    // 删除
    handleDel(row){
      this.$confirm('确认删除该数据吗？').then( async () => {
        await delMerchantOrder({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'操作成功',
              type:'success'
            });
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            });
          }
          this.init();
        })
      })
    },
    // 分页
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss"></style>
