<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="商品名称" prop="productName">
          <el-input placeholder="请输入商品名称" v-model="form.productName" ></el-input>
        </el-form-item>
        <el-form-item label="购买数量" prop="number">
          <el-input placeholder="请输入购买数量" v-model="form.number" type="number"></el-input>
        </el-form-item>
        <el-form-item label="费用（元）" prop="money">
          <el-input placeholder="请输入费用" v-model="form.money" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" placeholder="请输入备注" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { addMerchantOrder,editMerchantOrder } from '@/api/public.js';
  export default {
    props:['row','merchantId'], // 父组件携带参数
    data() {
      return {
        form:{ // 表单数据
            productName:"",
            number:"",
            remark:"",
            merchantId:null,
        },
        rules: { // 表单验证
          productName:[
            { required: true, message:'商品名称不得为空', trigger: 'blur',}
          ],
          number:[
            { required: true, message:'商品数量不得为空', trigger: "blur"}
          ],
          money:[
            { required:true,message:'费用不得为空', trigger:'blur'}
          ]
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form = this.row ? {...this.row} : this.form;
      this.form.merchantId = this.merchantId;
    },
  
    methods: {
      // 关闭弹窗
      close(form){
         this.$emit('close');
      },
      // 新增
      async add(){
        await addMerchantOrder({param:this.form}).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success',
            })
            this.$emit('close');
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      },
      // 编辑
      async edit(){
        await editMerchantOrder({param:this.form}).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success',
            })
            this.$emit('close');
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      },
      // 提交
      handleSubmit(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
              this.form.money = Number(this.form.money);
              this.form.number = Number(this.form.number);
                this.$confirm('确认提交吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  if(this.row){
                    this.edit();
                  }else{
                    this.add();
                  }
                })
            }else{
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  // 防止地图自动完成的对象被遮挡
  .tangram-suggestion {
      z-index: 9999 !important;
    }
   .el-drawer{
     overflow:auto !important;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  